import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '@/components/Loader';

import type { FullHeightWrapperWithLoaderProps } from './full-height-wrapper-with-loader.types';
import { S } from './full-height-wrapper-with-loader.styles';

export function FullHeightWrapperWithLoader({
  children,
  fullScreen = true,
  spinning = false,
  showLoader = true,
}: FullHeightWrapperWithLoaderProps) {
  if (!showLoader) return children;

  return (
    <S.FullHeightWrapper>
      <Loader fullScreen={fullScreen} spinning={spinning} />
      {children}
    </S.FullHeightWrapper>
  );
}

FullHeightWrapperWithLoader.propTypes = {
  fullScreen: PropTypes.bool,
  spinning: PropTypes.bool,
};
