import formTypeShortTextIcon from './assets/form-type-short-text.svg';
import formTypeLongTextIcon from './assets/form-type-long-text.svg';
import formTypeMCQIcon from './assets/form-type-mcq.svg';
import formTypeMSQIcon from './assets/form-type-msq.svg';
import formTypeDropdownIcon from './assets/form-type-dropdown.svg';
import formTypeAttachment from './assets/form-type-attachment.svg';
import formTypeTelephoneIcon from './assets/form-type-telephone.svg';
import formTypeEmailIcon from './assets/form-type-email.svg';
import formTypeIframeIcon from './assets/form-type-iframe.svg';
import formTypeImageIcon from './assets/form-type-image.svg';
import formTypeIdIcon from './assets/form-type-id.svg';
import formTypeSmartScreeningIcon from './assets/form-type-smart-screening.svg';
import constants from './utils/constants';

let jobs = 'https://jobs-dev.workstream.us/j';
let work = 'https://j-dev.wrkstrm.us';
let api = process.env.WS_API_BASE || 'https://api-dev.workstream.us';
let hr = 'https://hr-dev.workstream.us';
let checkrOuthURI = 'https://partners.checkr.com/authorize/dea84b11466867cc2c6876f3/signup?ref=dashboard';
let helloSignClientId = '1229905e27e23c3355cfe3c0e25c3cd3';
let skipDomainVerificationHelloSign = true;
let actionCableUrl = 'wss://api-dev.workstream.us/cable';
let googleOauthClientId = '282287906277-2qa62lpm9scas78vdn2ura39n8m784ql.apps.googleusercontent.com';
let adpConnectLink = 'https://apps.adp.com/en-US/apps/306100';
let wsSignupLink = 'https://signup-dev.workstream.us';
let sentryRelease = 'HRDefaultRelease';
// This DSN is for sentry bug tracking
const sentryDSN = 'https://e72a5e8c4d284a3cadc4d4c7e07b1d5c@o404030.ingest.sentry.io/5739186';
let indeedOauthClientId = '77ce2fbaad2d644711a3b65e9d7e6417b2890f92d0777032e73122cf8caf95eb';
let payrollAppLink = 'https://payroll.dev.workstream.us';
let authPortalLink = 'https://go.dev.workstream.us';

if (process.env.DEPLOY_ENV === 'develop') {
  // no changes
}

if (process.env.DEPLOY_ENV === 'production') {
  jobs = 'https://jobs.workstream.us/j';
  work = 'https://j.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-backend.workstream.us';
  hr = 'https://hr.workstream.us';
  wsSignupLink = 'https://signup.workstream.us';
  actionCableUrl = 'wss://api-backend.workstream.us/cable';
  checkrOuthURI = 'https://partners.checkr.com/authorize/c80af23a490f0a2a8658c2fd/signup?ref=dashboard';
  helloSignClientId = 'f83ba7bc9a42dd1a16a4d50ae7c4d20a';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '905873288640-3eqvmikk66nq9afo5rilc5uaijjqcm1d.apps.googleusercontent.com';
  adpConnectLink = 'https://apps.adp.com/en-US/apps/292061';
  sentryRelease = process.env.SENTRY_RELEASE;
  indeedOauthClientId = '95e7eb6c2b5029d39149a090f78fc29a0255fda453db3d8a3bde26a0d4d64e1e';
  payrollAppLink = 'https://payroll.workstream.us';
  authPortalLink = 'https://go.workstream.us';
}

if (process.env.DEPLOY_ENV === 'staging') {
  jobs = 'https://jobs-staging.workstream.us/j';
  work = 'https://j-staging.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-staging.workstream.us';
  hr = 'https://hr-staging.workstream.us';
  wsSignupLink = 'https://signup-staging.workstream.us';
  actionCableUrl = 'wss://api-staging.workstream.us/cable';
  helloSignClientId = '5a219c5403011d3eb59fda76c2c652db';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
  indeedOauthClientId = '9e95a5656c0fdf85778f8aa559e6038641419959d1d36060d697e83cc04279ed';
  payrollAppLink = 'https://payroll.staging.workstream.us';
  authPortalLink = 'https://go.staging.workstream.us';
}

if (process.env.DEPLOY_ENV === 'staging-1') {
  jobs = 'https://jobs-staging-1.workstream.us/j';
  work = 'https://j-staging-1.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-staging-1.workstream.us';
  hr = 'https://hr-staging-1.workstream.us';
  actionCableUrl = 'wss://api-staging-1.workstream.us/cable';
  helloSignClientId = '3b412e4e99c925f49f8893c60cabe12a';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
  authPortalLink = 'https://go.dev.workstream.us';
}

if (process.env.DEPLOY_ENV === 'staging-2') {
  jobs = 'https://jobs-staging-2.workstream.us/j';
  work = 'https://j-staging-2.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-staging-2.workstream.us';
  hr = 'https://hr-staging-2.workstream.us';
  actionCableUrl = 'wss://api-staging-2.workstream.us/cable';
  helloSignClientId = 'c71a647d5b333464a97ca6f097c87fa9';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
  indeedOauthClientId = 'd06199aef85c19a040502c1df6b55d4011c8c7c6915ca2aab41c0dca0ee6f7c6';
  authPortalLink = 'https://go.dev.workstream.us';
}

if (['staging-3', 'mfe-dev'].includes(process.env.DEPLOY_ENV as string)) {
  jobs = 'https://jobs-staging-3.workstream.us/j';
  work = 'https://j-staging-3.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-staging-3.workstream.us';
  hr = 'https://hr-staging-3.workstream.us';
  actionCableUrl = 'wss://api-staging-3.workstream.us/cable';
  helloSignClientId = 'b4b70fe61a3a6c26c5cf9be6ab947fe1';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
  indeedOauthClientId = '691d60f56c833228f482cbf98c41059960a97642d5220e5436ab6d18c9ba077d';
  authPortalLink = 'https://go.dev.workstream.us';

  if (process.env.DEPLOY_ENV === 'mfe-dev') {
    hr = 'https://mfe.dev.workstream.is';
  }
}

if (process.env.DEPLOY_ENV === 'staging-4') {
  jobs = 'https://jobs-staging-4.workstream.us/j';
  work = 'https://j-staging-4.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-staging-4.workstream.us';
  hr = 'https://hr-staging-4.workstream.us';
  actionCableUrl = 'wss://api-staging-4.workstream.us/cable';
  helloSignClientId = 'f2fce69f1167948fbd7d6599098503a0';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
  indeedOauthClientId = '7f799fd3845de5d31a119904fda9aa0c561de5a0f0fe7d1ac1e85bb6193b4af3';
  authPortalLink = 'https://go.dev.workstream.us';
}

if (process.env.DEPLOY_ENV === 'staging-seed') {
  jobs = 'https://jobs-staging-seed.workstream.us/j';
  work = 'https://j-staging-seed.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-staging-seed.workstream.us';
  hr = 'https://hr-staging-seed.workstream.us';
  actionCableUrl = 'wss://api-staging-seed.workstream.us/cable';
  helloSignClientId = '4ce491dc03205c76115ed7226b9e3c1b';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
}

if (process.env.DEPLOY_ENV === 'trunk') {
  jobs = 'https://jobs-trunk.workstream.us/j';
  work = 'https://j-trunk.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-trunk.workstream.us';
  hr = 'https://hr-trunk.workstream.us';
  actionCableUrl = 'wss://api-trunk.workstream.us/cable';
  helloSignClientId = '3830caf892ed60156f7d18ff054fa354';
  skipDomainVerificationHelloSign = false;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
  indeedOauthClientId = 'bf6af28b0a23153c3064b3a7ca4ec30d5b02355ab8a4f4cda30064a93823f49f';
}

if (process.env.DEPLOY_ENV === 'sandbox') {
  jobs = 'https://jobs-sandbox.workstream.us/j';
  work = 'https://j-sandbox.wrkstrm.us';
  api = process.env.WS_API_BASE || 'https://api-sandbox.workstream.us';
  hr = 'https://hr-sandbox.workstream.us';
  actionCableUrl = 'wss://api-sandbox.workstream.us/cable';
  helloSignClientId = '8e48e3a39e8c7b188daeedd8ac3686e8';
  skipDomainVerificationHelloSign = true;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
}

if (process.env.DEPLOY_ENV === 'local') {
  jobs = process.env.WS_JOBS_BASE || 'http://localhost:4000/j';
  work = process.env.WS_WORK_BASE || jobs;
  api = process.env.WS_API_BASE || 'http://localhost:3000';
  hr = process.env.WS_HR_BASE || 'http://localhost:8000';
  actionCableUrl = process.env.WS_CABLE_BASE || 'ws://localhost:3000/cable';
  googleOauthClientId = '282287906277-1gger361ttsi2brn8hlp6m0se5nt7o54.apps.googleusercontent.com';
}

if (process.env.DEPLOY_ENV === 'playground') {
  jobs = 'https://jobs-staging-2.workstream.us/j';
  work = 'https://j-staging-2.wrkstrm.us';
  api = process.env.BACKEND_PLAYGROUND_API || 'http://localhost:3000';
  hr = process.env.HR_PLAYGROUND_API || 'http://localhost:8000';
  actionCableUrl = 'wss://api-staging.workstream.us/cable';
  helloSignClientId = '3b412e4e99c925f49f8893c60cabe12a';
  skipDomainVerificationHelloSign = true;
  googleOauthClientId = '282287906277-3l5iefukiorvv0h76979k7k727c2ft67.apps.googleusercontent.com';
}

if (process.env.DEPLOY_ENV === 'test') {
  api = process.env.BACKEND_PLAYGROUND_API || 'http://localhost:3000';
}

export const previewHOST = jobs;
export const copyLinkHOST = work;
export const APIHOST = api;
export const HRHOST = hr;
export const CHECKR_OUTH_URI = checkrOuthURI;
export const HELLOSIGN_CLIENT_ID = helloSignClientId;
export const TEST_HELLOSIGN_CLIENT_ID =
  process.env.DEPLOY_ENV === 'production' ? helloSignClientId : '36cc64a976d913d1c484b78c8ec9be97';
export const SKIP_DOMAIN_VERIFICATION_HELLOSIGN = skipDomainVerificationHelloSign;
export const ACTION_CABLE_URL = actionCableUrl;
export const GOOGLE_OAUTH_CLIENT_ID = googleOauthClientId;
export const ADP_CONNECTOR_LINK = adpConnectLink;
export const WS_SIGNUP_LINK = wsSignupLink;
export const DEPLOY_ENV = process.env.DEPLOY_ENV;
export const SENTRY_RELEASE = sentryRelease;
export const SENTRY_DSN = sentryDSN;
export const ENABLE_REDUX_LOGGER = process.env.ENABLE_REDUX_LOGGER as unknown as boolean;
export const WS_DISABLE_WEBSOCKET = process.env.WS_DISABLE_WEBSOCKET as unknown as boolean;
export const ENABLE_MFE = process.env.ENABLE_MFE as unknown as boolean;
export const INDEED_OAUTH_REDIRECT_URL = `${api}/indeed/accounts/auth`;
export const INDEED_OAUTH_CLIENT_ID = indeedOauthClientId;
export const INDEED_ATS_KEY = 'W8B0I4D8S8';
export const PAYROLL_APP_LINK = payrollAppLink;
export const AUTH_PORTAL_LINK = authPortalLink;

export const stageTypes = [
  {
    name: 'Form',
    type: constants.STAGE_TYPE.ONLINE_FORM,
    description: 'Collect additional information or files from an applicant via an online form.',
  },
  {
    name: 'Quiz',
    type: constants.STAGE_TYPE.ONLINE_QUIZ,
    description: 'Ask an applicant to take a quiz, and move them according to their answers.',
  },
  {
    name: 'Scheduling',
    type: constants.STAGE_TYPE.SCHEDULING,
    description:
      'Automatically schedule onsite interviews, video calls, trainings, or any other meetings with your applicants.',
  },
  {
    name: 'Online signing',
    type: constants.STAGE_TYPE.OFFER,
    description:
      'Send documents to applicants for digital signature, such as offer letters, tax documents, or other custom forms.',
  },
  {
    name: 'Background check',
    type: constants.STAGE_TYPE.BACKGROUND_CHECK,
    description: 'Conduct background check on applicants.',
  },
  // {
  //   name: 'Phone Verification',
  //   type: 'phone_verification',
  //   description: "Send an automated SMS to verify an applicant's phone number.",
  // },
  {
    name: 'Review',
    type: constants.STAGE_TYPE.REVIEW,
    description: 'A short-term holding stage for manually reviewing applicants as soon as possible.',
  },
  {
    name: 'Long-term holding',
    type: constants.STAGE_TYPE.LONG_TERM_HOLD,
    description:
      'A long-term holding stage for keeping applicants for later if they are not ready to process right now.',
  },
  {
    name: 'Hiring complete',
    type: constants.STAGE_TYPE.HIRING_COMPLETE,
    description: 'A stage for placing applicants who have completed the interview process and are ready to be hired.',
  },
];

export const formElementInfo = {
  text_heading: {
    name: 'Text block',
    icon: formTypeLongTextIcon,
  },
  image: {
    name: 'Image',
    icon: formTypeImageIcon,
  },
  iframe: {
    name: 'Embedded content',
    icon: formTypeIframeIcon,
  },
  embed_youtube: {
    name: 'Embed YouTube video',
    icon: formTypeIframeIcon,
  },
  // resume: {
  //   name: 'Upload Resume',
  //   icon: formTypeAttachment,
  // },
};

export const formQuestionInfo = {
  smart_screening: {
    name: 'Smart screening',
    icon: formTypeSmartScreeningIcon,
  },
  wotc_questionnaire: {
    name: 'WOTC pre-questionnaire',
    icon: null,
  },
  short_text: {
    name: 'Short answer',
    icon: formTypeShortTextIcon,
  },
  long_text: {
    name: 'Paragraph answer',
    icon: formTypeLongTextIcon,
  },
  attachment: {
    name: 'File upload',
    icon: formTypeAttachment,
  },
  resume: {
    name: 'Upload Resume',
    icon: formTypeAttachment,
  },
  multiple_selection: {
    name: 'Checkboxes',
    icon: formTypeMSQIcon,
  },
  multiple_choice: {
    name: 'Multiple choice',
    icon: formTypeMCQIcon,
  },
  dropdown: {
    name: 'Dropdown',
    icon: formTypeDropdownIcon,
  },
  telephone: {
    name: 'Telephone',
    icon: formTypeTelephoneIcon,
  },
  email: {
    name: 'Email address',
    icon: formTypeEmailIcon,
  },
  identity: {
    name: 'ID number',
    icon: formTypeIdIcon,
  },
  date: {
    name: 'Date',
    icon: formTypeShortTextIcon,
  },
  weekly_time_slot: {
    name: 'Applicant Availability',
    icon: null,
  },
};

export const jobboards = [
  {
    id: 4,
    name: 'Indeed',
    description:
      'Indeed is an American worldwide employment-related search engine for job listings launched in November 2004 and is co-headquartered in Austin, Texas and Stamford, Connecticut with additional offices around the world.',
    status: 'live',
    logo: '/uploads/job_board/logo/4/a0e8d7044b195b3d15afc8087ef92f7c.png',
    chosen: false,
  },

  {
    id: 3,
    name: 'Workable',
    description:
      'Workable is an American worldwide employment-related search engine for job listings launched in November 2004 and is co-headquartered in Austin, Texas and Stamford, Connecticut with additional offices around the world.',
    status: 'coming_soon',
    logo: '/uploads/job_board/logo/4/a0e8d7044b195b3d15afc8087ef92f7c.png',
    chosen: false,
  },
];

export const automationRules = {
  [constants.AUTOMATIONS.APPLICATION_ADVANCE]: {
    triggerText: 'applicant submits application and passes smart screening (if applicable)',
    description: 'advance applicants on form response includes smart screen questions',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.FORM_ADVANCE]: {
    triggerText: 'applicant submits online form',
    description: 'advance applicants on form response',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.QUIZ_ADVANCE]: {
    triggerText: 'applicant submits quiz',
    description: 'advance applicants on quiz response',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.OFFER_SEND]: {
    triggerText: 'applicant is moved to this stage',
    description: 'send applicant offer',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.OFFER_ADVANCE]: {
    triggerText: 'applicant completes the signing request',
    description: 'advance applicants on offer response',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.OFFER_REMINDER]: {
    name: 'offer reminder',
    type: 'offer_remind',
    triggerText: 'applicant does not complete the signing request',
    description: '',
    allowMultiple: true,
  },

  [constants.AUTOMATIONS.QUIZ_REMINDER]: {
    name: 'quiz reminder',
    type: 'online_quiz_remind',
    triggerText: 'applicant does not respond to quiz',
    description: '',
    allowMultiple: true,
  },

  [constants.AUTOMATIONS.FORM_REMINDER]: {
    name: 'form reminder',
    type: 'form_remind',
    triggerText: 'applicant does not respond to form',
    description: '',
    allowMultiple: true,
  },

  [constants.AUTOMATIONS.MEETING_REMINDER]: {
    name: constants.AUTOMATIONS.MEETING_REMINDER,
    type: 'meeting_remind',
    triggerText: 'an event has been confirmed',
    description: '',
    allowMultiple: true,
  },

  [constants.AUTOMATIONS.SCHEDULING_REMINDER]: {
    name: constants.AUTOMATIONS.SCHEDULING_REMINDER,
    type: 'scheduling_remind',
    triggerText: 'applicant does not select a time',
    description: '',
    allowMultiple: true,
  },

  // BACKGROUND_CHECK_SEND: 'send background check request',
  // BACKGROUND_CHECK_REMINDER: 'background_check reminder',
  // BACKGROUND_CHECK_ADVANCE: 'background check advance',
  // Background check
  [constants.AUTOMATIONS.BACKGROUND_CHECK_SEND]: {
    name: constants.AUTOMATIONS.BACKGROUND_CHECK_SEND,
    triggerText: 'applicant is moved to this stage',
    description: 'send background check request',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.BACKGROUND_CHECK_ADVANCE]: {
    name: constants.AUTOMATIONS.BACKGROUND_CHECK_ADVANCE,
    triggerText: 'applicant passes background check',
    description: 'move applicant to next stage',
    allowMultiple: false,
  },

  [constants.AUTOMATIONS.BACKGROUND_CHECK_REMINDER]: {
    type: 'background_check_remind',
    name: constants.AUTOMATIONS.BACKGROUND_CHECK_REMINDER,
    triggerText: 'applicant does not respond to background check request',
    description: '',
    allowMultiple: true,
  },

  [constants.AUTOMATIONS.AUTO_REJECTION]: {
    type: 'auto_rejection',
    name: constants.AUTOMATIONS.AUTO_REJECTION,
    triggerText: 'applicant has been in this stage',
    description: '',
    allowMultiple: false,
  },
};

// Intercom settings
const intercomSettings = {
  appId: 'r6we9b43',
};

if (process.env.DEPLOY_ENV === 'production') {
  intercomSettings.appId = 'lmaakz1h';
}

export { intercomSettings };

// job boards branding color
const jobBoardsColors = {
  [constants.JOB_BOARD.INDEED_SPONSOR_NAME]: '#4684e7',
  [constants.JOB_BOARD.INDEED_FREE_NAME]: '#4684e7',
  [constants.JOB_BOARD.KSL_SPONSOR_NAME]: '#294d98',
  [constants.JOB_BOARD.CRAIGSLIST_SPONSOR_NAME]: '#5d008f',
  [constants.JOB_BOARD.ZIPRECRUITER_NAME]: '#83b108',
  [constants.JOB_BOARD.LINKEDIN_NAME]: '#225982',
  [constants.JOB_BOARD.MONSTER_NAME]: '#7c309f',
  [constants.JOB_BOARD.GOOGLE_NAME]: '#3e51b5',
  [constants.JOB_BOARD.GLASSDOOR_NAME]: '#0aaa42',
};

const jobBoardsShortDescription = {
  [constants.JOB_BOARD.KSL_SPONSOR_NAME]: 'KSL is the #1 local media site in the entire nation.',
  [constants.JOB_BOARD.CRAIGSLIST_SPONSOR_NAME]:
    'Craigslist is a local classified advertisements website with a dedicated job section.',
};

export { jobBoardsColors, jobBoardsShortDescription };

export const stageTypesSlugNameMap = {
  [constants.STAGE_TYPE.APPLICATION_FORM]: 'application form',
  [constants.STAGE_TYPE.ONLINE_QUIZ]: 'quiz',
  [constants.STAGE_TYPE.ONLINE_FORM]: 'form',
  [constants.STAGE_TYPE.SCHEDULING]: 'Scheduling',
  [constants.STAGE_TYPE.OFFER]: 'Online signing',
  [constants.STAGE_TYPE.BACKGROUND_CHECK]: 'background check',
};

export const formStageApplicantStatusNameMapping = {
  not_started: 'Not Started',
  submitted: 'Submitted',
};

export const quizStageApplicantStatusNameMapping = {
  not_started: 'Not Started',
  submitted: 'Submitted',
  passed: 'Passed',
  failed: 'Failed',
};

export const employeeStatusNameMapping = {
  not_started: 'New',
  in_progress: 'In progress',
  completed: 'Completed',
  suspended: 'Removed',
  action_required: 'Action Required',
};

export const employeeRecordStatusNameMapping = {
  hired: 'Mark as Hired',
  active: 'Active',
  offboarded: 'Offboarded',
  onboarding: 'Onboarding',
};

export const moduleStatusNameMapping = {
  in_progress: 'In progress',
  completed: 'Complete',
};

export const applicationActionNameCodeMap = {
  MOVE_TO_STAGE: 0,
  SEND_EMAIL: 2,
  SEND_SMS: 3,
  ADD_NOTE: 4,
  REJECT: 5,
  SEND_SIGNING_REQUEST: 6,
  UN_REJECT: 7,
  SHARE_APPLICANT: 8,
  MOVE_TO_POSITION: 9,
  SEND_REMINDER: 10,
  ORDER_BACKGROUND_CHECK: 11,
  CONFIRM_MEETING: 12,
  REQUEST_RESCHEDULE: 13,
  CANCEL_MEETING: 14,
  MARK_HIRED: 15,
  MARK_NOT_HIRED: 16,
  ASSIGN_TIME: 17,
  PRINT_APPLICANT: 19,
  MARK_SCHEDULE_COMPLETE: 20,
  MARK_SCHEDULE_NO_SHOW: 21,
  START_ONBOARDING: 22,
  SEND_TO_PAYLOCITY: 23,
  SEND_TO_UKG: 24,
  MARK_AS_INACTIVE: 25,
  SEND_EMAIL_INACTIVE: 26,
  SEND_SMS_INACTIVE: 27,
  REJECT_APPLICANT: 28,
  UNREJECT_APPLICANT: 29,
};

export const pdfInEmailNotificationTypes = [
  'new_applicant',
  'move_position',
  'applicant_scheduling_stage',
  'meeting',
  'hr_next_day_schedule',
  'applicant_signing_complete',
];

export const liteTierNotificationTypes = ['new_applicant', 'applicant_replies_email'];

export const YOUTUBE_API_KEY = 'AIzaSyCyRNh904QrFd54lpv7SQKvlNEcdqXu3cc';

export const WOTC_QUESTIONS = {
  is_under_40: 'Are you under age 40?',
  has_snap: 'Do you, or your family, receive SNAP Benefits? (Food Stamps or Cash Assistance)',
  has_ssi: 'Do you receive SSI Benefits?',
  is_veteran: 'Are you a US Veteran?',
  is_felon: 'Are you an ex-felon?',
  is_unemployed: 'Have you been unemployed for 6 months?',
};

export const SELF_SERVE_VERSION = '2020.08.26 Podding';
